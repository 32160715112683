
import Vue from '@/vueTyped';
import OverlayModal from '@/layouts/OverlayModal.vue';
import { CurrentUser, Post } from '@/types';
import TransitionExpand from './TransitionExpand.vue';
import AuthButton from './AuthButton.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                title: 'Report',
                notSignedIn: 'You must sign in to report a post.',
                reasons: {
                    spam: 'It’s Spam',
                    nudity: 'Nudity or sexual activity',
                    hate: 'Hate speech or symbols',
                    violence: 'Violence or dangerous organizations',
                    illegalSale: 'Sale of illegal or regulated goods',
                    bullying: 'Bullying or harrassment',
                    copywrong: 'Intellectual property violation',
                    suicide: 'Suicide or self-injury',
                },
                details: 'Please add any details we should know',
                cancel: 'Cancel',
                signIn: 'Sign in',
                submit: 'Submit',
                close: 'Close',
                thanks: 'Thanks for your report, someone will look into it soon!',
                error: 'Sorry, something went wrong when sending your report. Please try again!',
            },

            es: {
                title: 'Informe',
                notSignedIn: 'Debes iniciar sesión para denunciar un mensaje.',
                reasons: {
                    spam: 'Es Spam',
                    nudity: 'Desnudez o actividad sexual',
                    hate: 'Discursos o símbolos de odio',
                    violence: 'Organizaciones violentas o peligrosas',
                    illegalSale: 'Venta de productos ilegales o regulados',
                    bullying: 'Intimidación o acoso',
                    copywrong: 'Violación de la propiedad intelectual',
                    suicide: 'Suicidio o autolesiones',
                },
                details: 'Por favor, añada cualquier detalle que debamos conocer',
                cancel: 'Cancelar',
                signIn: 'Acceder',
                submit: 'Enviar',
                close: 'Cerrar',
                thanks: 'Gracias por su informe, ¡alguien lo investigará pronto!',
                error: 'Lo sentimos, algo ha ido mal al enviar su informe. Por favor, inténtelo de nuevo.',
            },
        },
    },

    components: {
        OverlayModal,
        TransitionExpand,
        AuthButton,
    },

    props: {
        postId: {
            type: String as () => Post['id'],
            required: true,
        },
    },

    data() {
        return {
            progress: 0,
            reason: null,
            details: '',
            submissionSuccessful: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        async handleSubmit() {
            try {
                this.progress += 1;
                const reason = [this.reason, this.details].filter(Boolean).join(' • ');
                const { error } = await this.$store.dispatch('reportPost', { postId: this.postId, reason });
                if (error) throw error;
                this.submissionSuccessful = true;
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.$t('error'),
                });
            } finally {
                this.progress -= 1;
            }
        },
    },
});
